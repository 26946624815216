/**
 * Transforms backend API URL to corresponding frontend URL for sharing
 * @param {string} apiUrl - Backend API URL
 * @returns {string} Frontend base URL for sharing
 */
export function getFrontendUrlFromApi(apiUrl) {
  // Handle localhost
  if (apiUrl.includes('localhost')) {
    return 'https://localhost:8080';
  }

  // Handle dev environment
  if (apiUrl.includes('devapi')) {
    return 'https://devapp.traversal.com';
  }

  // Handle staging environment
  if (apiUrl.includes('staging-traversal-agent')) {
    return 'https://doapp-staging.traversal.com';
  }

  // Handle DO production environment
  if (apiUrl.includes('traversal-agent')) {
    return 'https://doapp.traversal.com';
  }

  // Default to production environment
  return 'https://app.traversal.com';
} 