<template>
  <div>
    <!-- Log Graphs Section -->
    <div v-if="logGraphs.length > 0">
      <h1 class="section-header">Log Symptoms Found (Ranked)</h1>
      <div class="carousel-container">
        <div class="carousel-track">
          <div class="graphs-grid carousel-slide">
            <div v-for="(graph, graphIndex) in logGraphs" :key="'log-graph-' + graphIndex" 
                 class="graph-container" @click="openModal(graph)">
              <!-- Add platform link button -->
              <div v-if="graph.platform_link" class="inline-exit">
                <MiniButton
                  type="secondary"
                  icon="exitarrow"
                  @click.stop="openPlatformLink(graph.platform_link)"
                  class="exit-button"
                />
              </div>
              <div class="graph-title">
                <TruncateTooltip :text="graph.query_title || graph.query || 'Log Metric'"/>
              </div>
              <highcharts-chart :options="getMetricChartOptions(graph)" :highcharts="Highcharts" />

              <template v-if="graph.sample_logs?.logs.length">
                <div class="logs-section">
                  <div class="logs-title">
                    {{ graph.sample_logs.logs.length }} Relevant Logs
                  </div>
                  <div class="logs-container">
                    <div>
                      <div class="log-timestamp">
                        <TruncateTooltip :text="`${graph.sample_logs.logs[0].message} - ${graph.sample_logs.logs[0].timestamp}`" />
                      </div>
                      <div class="log-message">
                        {{ formatLogFields(graph.sample_logs.logs[0]) }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div class="graph-tags" v-if="graph.logs_summary_stats">
                <div v-for="stat in graph.logs_summary_stats" :key="stat.field_name" class="stat-row">
                  <span class="stat-field-name">{{ stat.field_name }}:</span>
                  <div class="stat-tags">
                    <span v-for="(count, value) in stat.value_counts" :key="value" class="graph-tag">
                      {{ value }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Metric Graphs Section -->
    <div v-if="metricGraphs.length > 0">
      <h1 class="section-header2">Metric Symptoms Found (Ranked)</h1>
      <div class="carousel-container">
        <div class="carousel-track">
          <div class="graphs-grid carousel-slide">
            <div v-for="(graph, graphIndex) in metricGraphs" :key="'metric-graph-' + graphIndex" 
                 class="graph-container" @click="openModal(graph)">
              <!-- Add platform link button -->
              <div v-if="graph.platform_link" class="inline-exit">
                <MiniButton
                  type="secondary"
                  icon="exitarrow"
                  @click.stop="openPlatformLink(graph.platform_link)"
                  class="exit-button"
                />
              </div>
              <div class="graph-title">
                <TruncateTooltip :text="graph.query_title || graph.query || 'Metric'"/>
              </div>
              <highcharts-chart :options="getMetricChartOptions(graph)" :highcharts="Highcharts" />
              <div class="graph-tags" v-if="graph.logs_summary_stats">
                <div v-for="stat in graph.logs_summary_stats" :key="stat.field_name" class="stat-row">
                  <span class="stat-field-name">{{ stat.field_name }}:</span>
                  <div class="stat-tags">
                    <span v-for="(count, value) in stat.value_counts" :key="value" class="graph-tag">
                      {{ value }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add modal -->
    <GraphModal 
      :show="showModal"
      :graph="selectedGraph"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { getEvidenceChartOptions } from "@/utils/evidenceChartUtils";
import { Chart } from "highcharts-vue"; 
import TruncateTooltip from "@/components/TruncateTooltip.vue";
import GraphModal from './GraphModal.vue';
import MiniButton from './Buttons/MiniButton.vue';

export default {
  name: "EvidenceGraphs",
  components: {
    HighchartsChart: Chart,
    TruncateTooltip,
    GraphModal,
    MiniButton,
  },
  props: {
    metricGraphs: {
      type: Array,
      required: true,
    },
    logGraphs: {
      type: Array,
      required: true,
    },
    timeOfInterest: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      showModal: false,
      selectedGraph: null,
    };
  },
  methods: {
    getMetricChartOptions(graph) {
      return getEvidenceChartOptions(graph, {
        noTitle: true,
        timeOfInterest: graph.time_of_interest,
        hasLogs: graph.sample_logs?.logs.length > 0
      });
    },
    openModal(graph) {
      this.selectedGraph = graph;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedGraph = null;
    },
    openPlatformLink(url) {
      window.open(url, '_blank');
    },
    formatLogFields(log) {
      if (!log.top_fields || !log.data) return '';
      return log.top_fields
        .map(field => `${field}: ${log.data[field]}`)
        .join(' | ');
    },
  },
};
</script>

<style scoped>

.carousel-slide {
  min-width: 100%;
  padding-bottom: 14px;
  overflow-x: auto;
}

/* Scrollbar styling */
.carousel-slide::-webkit-scrollbar {
  height: 12px;
  cursor: pointer;
}

.carousel-slide::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 12px;
  /* Add these properties to improve hitbox */
  background-clip: padding-box;
  border: 1px solid transparent;
  min-height: 12px;
}

.carousel-slide::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 6px;
  /* Add margin to ensure track is clickable */
  margin: 0 2px;
}

.carousel-track {
  display: flex;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  margin: 0 auto;
  will-change: transform;
}

.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.section-header {
  font-size: 24px;
  font-weight: 700;
  color: var(--colors-white, #fff);
  margin-bottom: 12px;
}

.section-header2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--colors-white, #fff);
  margin-bottom: 12px;
  margin-top: 16px;
}


.graphs-grid {
  display: flex;
  gap: 18px;
  width: 100%;
}

.graph-container {
  margin-top: 12px;
  width: 422px;
  min-width: 422px;
  max-width: 422px;
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  transition: border-color 0.15s ease, transform 0.15s ease;
  will-change: transform, border-color;
  position: relative;
}

.graph-container:hover {
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
}

.graph-description {
  color: var(--colors-white, #fff);
  font-size: 18px;
  line-height: 1.4;
  font-weight: 700;
}

.graph-tags {
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow-x: auto;
  background: #161F15;
  margin: 0 -12px -12px -12px;
  padding: 12px 20px;
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid var(--colors-base-neutral-gray-100, #353E34);
  height: 64px;
  min-height: 64px;
  max-height: 64px;
}

.stat-row {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: fit-content;
}

.stat-field-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--colors-base-neutral-gray-500, #C2C5C2);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.stat-tags {
  display: flex;
  gap: 4px;
}

.graph-tag {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--colors-base-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08));
  white-space: nowrap;
  color: var(--colors-base-neutral-gray-700, #E7E8E7);

  /* text-xxs/semibold */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */  
}

@media (max-width: 700px) {
  .graphs-grid {
    flex-direction: column;
  }

  .graph-container {
    width: 100% !important;
    min-width: unset;
  }
}

/* Optimize highcharts rendering */
.graph-container :deep(.highcharts-container) {
  will-change: transform;
}

.inline-exit {
  position: absolute;
  top: 8px;
  right: 8px;
  margin: 0;
  z-index: 1;
}

.exit-button {
  margin-top: 0px;
  margin-bottom: 0px;
}

.graph-title {
  padding-right: 34px; /* Leave space for the button */
  width: 100%;
  font-size: 0.875rem; /* Add this line to make title smaller */
}

.graph-title :deep(.truncate-text) {
  color: var(--colors-base-foreground, #FFF);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.compact-chart {
  height: 160px;
}

.compact-chart :deep(.highcharts-container) {
  height: 160px !important;
}

.compact-tags {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}

.logs-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 12px;
  height: 160px;
}

.logs-title {
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.logs-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 4px;
  overflow: hidden;
}

.log-timestamp {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--colors-base-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08));
}

.log-message {
  display: flex;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  background: var(--colors-base-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  line-height: 1.3;
}
</style>
