<template>
  <div v-if="show" class="modal-overlay" @click="$emit('close')">
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <div class="modal-title">
          <TruncateTooltip :text="graph.query_title || graph.query || 'Metric'"/>
        </div>
        <div class="modal-actions">
          <div class="modal-actions-left">
            <MiniButton
              v-if="graph.platform_link"
              type="secondary"
              icon="exitarrow"
              @click="openPlatformLink(graph.platform_link)"
            />
            <MiniButton
              type="secondary"
              :icon="copyLoading ? 'loading' : copySuccess ? 'success' : 'copy'"
              @click="downloadAsPNG"
              :disabled="copyLoading"
            />
          </div>
          <MiniButton
            type="ghost"
            icon="cross"
            @click="$emit('close')"
          />
        </div>
      </div>
      
      <div class="modal-main-content">
        <div class="modal-chart-section">
          <div :class="{'compact-modal-chart': graph.sample_logs?.logs.length}">
            <highcharts-chart 
              :options="getModalChartOptions(graph)" 
              :highcharts="Highcharts"
            />
          </div>
        </div>

        <template v-if="graph.sample_logs?.logs.length">
          <div class="modal-logs-section">
            <div class="logs-header">
              <h3>{{ graph.sample_logs.logs.length }} Sample Logs</h3>
              <div class="logs-nav">
                <div class="logs-nav-scroll">
                  <button 
                    v-for="(log, idx) in graph.sample_logs.logs" 
                    :key="idx"
                    :class="['log-nav-button', { active: selectedLogIndex === idx }]"
                    @click="selectedLogIndex = idx"
                  >
                    Log {{ idx + 1 }}
                  </button>
                </div>
              </div>
            </div>
            
            <div class="logs-content-wrapper">
              <div class="log-content">
                <div class="log-overview">
                  <h4>Log Details:</h4>
                  <div class="log-detail-row">
                    <span class="log-label">Message:</span>
                    <div class="log-message">{{ currentLog.message }}</div>
                  </div>
                  <div class="log-detail-row">
                    <span class="log-label">Timestamp:</span>
                    <div class="log-timestamp">{{ currentLog.timestamp }}</div>
                  </div>
                </div>

                <div class="log-data-section">
                  <h4>Top Fields:</h4>
                  <VueJsonPretty
                    :data="topFieldsData"
                    :deep="1"
                    :show-double-quotes="true"
                    :show-length="false"
                    class="json-viewer top-fields-viewer"
                    :theme="'dark'"
                    :show-icon="true"
                  />
                </div>
                
                <div class="log-data-section">
                  <h4>Full Log Data:</h4>
                  <VueJsonPretty
                    :data="currentLog.data"
                    :deep="2"
                    :show-double-quotes="true"
                    :show-length="true"
                    class="json-viewer"
                    :theme="'dark'"
                    :show-icon="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="graph-tags" v-if="graph.logs_summary_stats">
        <h3 class="summary-stats-header">Key Metadata</h3>
        <div v-for="stat in graph.logs_summary_stats" :key="stat.field_name" class="stat-row">
          <span class="stat-field-name">{{ stat.field_name }}:</span>
          <div class="stat-tags">
            <span v-for="(count, value) in stat.value_counts" :key="value" class="graph-tag">
              {{ value }} : {{ count }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import TruncateTooltip from "@/components/TruncateTooltip.vue";
import { getEvidenceChartOptions } from "@/utils/evidenceChartUtils";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import MiniButton from './Buttons/MiniButton.vue';
import html2canvas from 'html2canvas';
import { copyImageToClipboard } from '@/utils/clipboardUtils';

export default {
  name: "GraphModal",
  components: {
    HighchartsChart: Chart,
    TruncateTooltip,
    VueJsonPretty,
    MiniButton,
  },
  props: {
    show: Boolean,
    graph: Object,
  },
  data() {
    return {
      selectedLogIndex: 0,
      copyLoading: false,
      copySuccess: false,
    };
  },
  computed: {
    currentLog() {
      return this.graph?.sample_logs?.logs[this.selectedLogIndex] || {};
    },
    topFieldsData() {
      const data = {};
      if (this.currentLog.top_fields && this.currentLog.data) {
        this.currentLog.top_fields.forEach(field => {
          data[field] = this.currentLog.data[field];
        });
      }
      return data;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    getModalChartOptions(graph) {
      return getEvidenceChartOptions(graph, {
        noTitle: true,
        timeOfInterest: graph.time_of_interest,
        modalView: true  // New flag for modal view
      });
    },
    handleKeydown(e) {
      if (this.show && e.key === 'Escape') {
        this.$emit('close');
      }
    },
    openPlatformLink(url) {
      window.open(url, '_blank');
    },
    async downloadAsPNG() {
      try {
        this.copyLoading = true;
        const modalContent = this.$el.querySelector('.modal-content');
        if (!modalContent) return;

        // Create clone
        const clone = modalContent.cloneNode(true);
        document.body.appendChild(clone);
        clone.style.position = 'absolute';
        clone.style.left = '-9999px';
        clone.style.top = '-9999px';

        // Fix text colors in clone
        const logHeaders = clone.querySelectorAll('.log-overview h4, .log-data-section h4, .summary-stats-header');
        logHeaders.forEach(header => {
          header.style.color = '#ffffff';
        });

        const jsonViewer = clone.querySelectorAll('.vjs-tree-node');
        jsonViewer.forEach(node => {
          const key = node.querySelector('.vjs-key');
          if (key) {
            key.style.color = '#ffffff';
          }
        });

        // Wait for clone to be fully rendered
        await new Promise(resolve => setTimeout(resolve, 100));

        const canvas = await html2canvas(clone, {
          backgroundColor: '#1A231A',
          scale: 2,
          logging: false,
          useCORS: true,
          allowTaint: true,
        });

        await copyImageToClipboard(canvas);
        this.copySuccess = true;
        
        setTimeout(() => {
          this.copySuccess = false;
        }, 3000);

      } catch (err) {
        console.error('Failed to copy/download:', err);
      } finally {
        this.copyLoading = false;
        const clone = document.querySelector('div[style*="-9999px"]');
        if (clone) document.body.removeChild(clone);
      }
    },
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #1A231A;
  padding: 24px;
  border-radius: 12px;
  width: 90%;
  max-width: 1400px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 16px;
}

.modal-title {
  flex: 1;
  min-width: 0; /* Allows flex item to shrink below content size */
}

.modal-actions {
  display: flex;
  gap: 8px;
  flex-shrink: 0; /* Prevents buttons from shrinking */
}

.modal-actions-left {
  display: flex;
  gap: 8px;
}

.modal-main-content {
  display: flex;
  gap: 24px;
  margin-top: 16px;
  min-height: 500px;
}

.modal-chart-section {
  flex: 1;
  min-width: 0;
}

.modal-logs-section {
  flex: 1;
  min-width: 0;
  max-width: 50%;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  padding-left: 24px;
  display: flex;
  flex-direction: column;
}

.logs-content-wrapper {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.log-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding-right: 12px;
}

.logs-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
}

.logs-header h3 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.logs-nav {
  width: 100%;
  overflow: hidden;
}

.logs-nav-scroll {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding-bottom: 4px;
}

.logs-nav-scroll::-webkit-scrollbar {
  height: 6px;
  cursor: pointer;
}

.logs-nav-scroll::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 6px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.logs-nav-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.log-nav-button {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.log-nav-button.active {
  background: rgba(255, 255, 255, 0.16);
  border-color: rgba(255, 255, 255, 0.3);
}

.log-data-section {
  margin-top: 16px;
}

.log-data-section h4 {
  font-size: 14px;
  margin: 0 0 8px 0;
  color: #fff;
}

.graph-tags {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-x: auto;
  background: #161F15;
  margin: 24px -24px -24px -24px;
  padding: 12px;
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid var(--colors-base-neutral-gray-100, #353E34);
}

.stat-row {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.stat-field-name {
  color: var(--colors-neutral-gray-600, #dbdcda);
  font-size: 14px;
}

.stat-tags {
  display: flex;
  gap: 4px;
}

.graph-tag {
  background: var(--colors-neutral-gray-50, #1f291f);
  color: var(--colors-neutral-gray-600, #dbdcda);
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}

.json-viewer {
  background: rgba(255, 255, 255, 0.04) !important;
  padding: 16px !important;
  border-radius: 4px !important;
  overflow: auto !important;
}

.top-fields-viewer {
  margin-bottom: 16px;
}

.log-overview {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
}

.log-detail-row {
  margin-top: 12px;
}

.log-label {
  display: block;
  color: var(--colors-base-neutral-gray-500, #C2C5C2);
  font-size: 12px;
  margin-bottom: 4px;
}

.log-message {
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
}

.log-timestamp {
  color: var(--colors-base-neutral-gray-700, #E7E8E7);
  font-family: monospace;
  font-size: 13px;
}

.log-content::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

.log-content::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 6px;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.log-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.modal-content :deep(.truncate-tooltip) {
  max-width: calc(100% - 200px); /* Leave space for the action buttons */
  margin-right: 100px; /* Prevent overlap with buttons */
}
</style> 