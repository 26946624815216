import { getMetricChartOptions } from './chartUtils';

export function getEvidenceChartOptions(metricQuery, options = {}) {
  // If this is a single-series view, modify the query to only show that series
  const modifiedQuery = {
    ...metricQuery,
    // Only use the single series if it's a single-series view
    metrics: metricQuery.metrics || []
  };

  const baseOptions = getMetricChartOptions(modifiedQuery, false, {
    ...options,
    noTitle: true,
    timeOfInterest: metricQuery.time_of_interest || options.timeOfInterest
  });

  return {
    ...baseOptions,
    chart: {
      ...baseOptions.chart,
      height: options.modalView ? 500 : (options.hasLogs ? 164 : 294),  // Adjust height based on logs presence
      spacing: [10, 10, 0, 10], // top, right, bottom, left
      reflow: true,
      events: {
        // Add mousemove event handler for synchronization
        load: function() {
          this.candidateId = options.candidateId;
          this.container.style.cursor = 'crosshair';
        }
      }
    },
    // Add crosshair configuration
    xAxis: {
      ...baseOptions.xAxis,
      crosshair: {
        color: 'rgba(255, 255, 255, 0.2)',
        width: 1,
        dashStyle: 'Dash',
        zIndex: 5
      }
    },
    tooltip: {
      ...baseOptions.tooltip,
      shared: true,
      crosshairs: true
    },
    // Add custom plot options for synchronized behavior
    plotOptions: {
      ...baseOptions.plotOptions,
      series: {
        ...baseOptions.plotOptions?.series,
        point: {
          events: {
            mouseOver: function() {
              const chart = this.series.chart;
              const candidateId = chart.candidateId;
              const event = new CustomEvent('sync-crosshair', {
                detail: {
                  candidateId: candidateId,
                  x: this.x
                }
              });
              window.dispatchEvent(event);
            }
          }
        }
      }
    },
    legend: {
      ...baseOptions.legend,
      height: options.modalView ? 100 : 76,  // Adjusted for modal
      padding: 0,
      margin: 0,
      itemMarginTop: 4,
      itemMarginBottom: 4,
    }
  };
} 