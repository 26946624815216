<template>
  <div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <h3>Share Session</h3>
      <div class="share-link-container">
        <p>Copy this link to share the session:</p>
        <div class="link-box">
          <span class="shared-link">{{ sharedLink }}</span>
          <MiniButton
            type="secondary"
            :icon="copyLoading ? 'loading' : copySuccess ? 'success' : 'copy'"
            @click="copyLink"
            :disabled="copyLoading"
            class="copy-button"
          />
        </div>
      </div>
      <div class="modal-buttons">
        <PrimaryButton type="standard" @click="closeModal">Close</PrimaryButton>
        <PrimaryButton type="primary" @click="unshareSession">Unshare</PrimaryButton>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from './Buttons/PrimaryButton.vue';
import MiniButton from './Buttons/MiniButton.vue';

export default {
  name: 'ShareModal',
  components: {
    PrimaryButton,
    MiniButton,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    sharedLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      copyLoading: false,
      copySuccess: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    unshareSession() {
      this.$emit('unshare');
    },
    async copyLink() {
      this.copyLoading = true;
      try {
        await navigator.clipboard.writeText(this.sharedLink);
        this.copyLoading = false;
        this.copySuccess = true;
        setTimeout(() => {
          this.copySuccess = false;
        }, 2000);
      } catch (err) {
        console.error('Failed to copy:', err);
        this.copyLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--colors-black, #0C160B);
  padding: 24px;
  border-radius: 16px;
  width: 500px;
  max-width: 90%;
  border: 1px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
  box-shadow: 0px 4px 40px 0px #000;
}

.modal-content h3 {
  color: var(--colors-white, #FFF);
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 20px 0;
}

.share-link-container {
  margin-bottom: 24px;
}

.share-link-container p {
  color: var(--colors-neutral-gray-600, #DBDCDA);
  font-size: 14px;
  margin-bottom: 12px;
}

.link-box {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background-color: var(--colors-neutral-gray-50, #1F291F);
  border: 1px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
  border-radius: 8px;
}

.shared-link {
  flex: 1;
  color: var(--colors-white, #FFF);
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.copy-button {
  flex-shrink: 0;
}

.modal-buttons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.modal-buttons :deep(button) {
  min-width: 100px;
}
</style>
