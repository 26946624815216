export function copyImageToClipboard(canvas) {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve) => {
    // Try modern clipboard API first
    if (navigator.clipboard && navigator.clipboard.write) {
      canvas.toBlob(blob => {
        navigator.clipboard.write([
          new ClipboardItem({
            'image/png': blob
          })
        ])
        .then(() => resolve())
        .catch(() => {
          // If clipboard write fails, continue to fallback
          console.log('Clipboard API failed, trying fallback...');
          handleFallback();
        });
      }, 'image/png');
      return;
    }

    handleFallback();

    function handleFallback() {
      let textarea;
      try {
        // Fallback for Safari and older browsers
        const dataUrl = canvas.toDataURL('image/png');
        
        // Create a temporary textarea
        textarea = document.createElement('textarea');
        textarea.value = dataUrl;
        textarea.setAttribute('readonly', ''); // Make it readonly to be less obtrusive
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';
        document.body.appendChild(textarea);

        // Special handling for iOS
        if (navigator.userAgent.match(/ipad|iphone/i)) {
          const range = document.createRange();
          range.selectNodeContents(textarea);
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
          textarea.setSelectionRange(0, 999999);
        } else {
          textarea.select();
        }

        const successful = document.execCommand('copy');
        if (!successful) {
          triggerDownload(dataUrl);
        }
        resolve();
      } catch (err) {
        // If all else fails, trigger download
        const dataUrl = canvas.toDataURL('image/png');
        triggerDownload(dataUrl);
        resolve();
      } finally {
        if (textarea) {
          document.body.removeChild(textarea);
        }
      }
    }

    function triggerDownload(dataUrl) {
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'snapshot.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
} 