<template>
  <div class="timeline">
    <div v-for="(candidate, index) in candidateIssues" :key="index" class="timeline-segment" :ref="`candidate${index}`">
      <div class="segment-header">
        <div class="segment-header-content">
          <h2>Candidate {{ index + 1 }}: <span v-html="highlightText(candidate.title, candidate.highlights)"></span></h2>
          <div class="confidence-container">
            <div v-if="candidate.confidence === 'High'" class="high-confidence">Confidence: {{ candidate.confidence }}</div>
            <div v-else class="medium-confidence">Confidence: {{ candidate.confidence }}</div>
            <MiniButton
              type="secondary"
              :icon="copyLoading === index ? 'loading' : copySuccess === index ? 'success' : 'copy'"
              @click="copyAsPNG(index)"
              :disabled="copyLoading === index"
            />
          </div>
        </div>
        <p v-html="highlightText(candidate.root_cause_summary, candidate.highlights)"></p>
      </div>

      <!-- <pre class="debug-evidence">
        {{ JSON.stringify(getEvidenceGraph(candidate.events[0].graph_ids[0]), null, 2) }}
      </pre> -->
      <div class="segment-content">
        <div v-for="(event, eventIndex) in candidate.events" :key="eventIndex" class="event-section">
          <div class="event-index-header">
            <div class="event-index">{{ eventIndex + 1 }}</div>
            <div class="event-index-divider"></div>
          </div>
          <div class="event-summary">
            <TruncateTooltip :text="event.summary" />
          </div>
          <div class="chart">
            <div v-for="graphId in event.graph_ids" :key="graphId" class="graph-container" @click="openModal(getEvidenceGraph(graphId))">
              <template v-if="getEvidenceGraph(graphId)">
                <div v-if="getEvidenceGraph(graphId).platform_link" class="inline-exit">
                  <MiniButton
                    type="secondary"
                    icon="exitarrow"
                    @click.stop="openPlatformLink(getEvidenceGraph(graphId).platform_link)"
                    class="exit-button"
                  />
                </div>
                <div class="graph-title">
                  <TruncateTooltip :text="getEvidenceGraph(graphId).query_title || getEvidenceGraph(graphId).query || 'Metric'" />
                </div>
                <div :class="{'compact-chart': getEvidenceGraph(graphId).sample_logs?.logs.length}">
                  <highcharts-chart :options="getMetricChartOptions(getEvidenceGraph(graphId))" :highcharts="Highcharts" />
                </div>
                
                <template v-if="getEvidenceGraph(graphId).sample_logs?.logs.length">
                  <div class="logs-section">
                    <div class="logs-title">
                      {{ getEvidenceGraph(graphId).sample_logs.logs.length }} Relevant Logs
                    </div>
                    <div class="logs-container">
                      <div>
                        <div class="log-timestamp">
                          <TruncateTooltip :text="`${getEvidenceGraph(graphId).sample_logs.logs[0].message} - ${getEvidenceGraph(graphId).sample_logs.logs[0].timestamp}`" />
                        </div>
                        <div class="log-message">
                          {{ formatLogFields(getEvidenceGraph(graphId).sample_logs.logs[0]) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                
                <div :class="{'compact-tags': getEvidenceGraph(graphId).sample_logs?.length}" class="graph-tags" v-if="getEvidenceGraph(graphId).logs_summary_stats">
                  <div v-for="stat in getEvidenceGraph(graphId).logs_summary_stats" :key="stat.field_name" class="stat-row">
                    <span class="stat-field-name">{{ stat.field_name }}:</span>
                    <div class="stat-tags">
                      <span v-for="(count, value) in stat.value_counts" :key="value" class="graph-tag">
                        {{ value }}
                      </span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="graph-not-found">Graph {{ graphId }} not found</div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Add modal -->
  <GraphModal 
    :show="showModal"
    :graph="selectedGraph"
    @close="closeModal"
  />
</template>

<script>
// import TruncateTooltip from './TruncateTooltip.vue';

import { Chart } from "highcharts-vue";

import TruncateTooltip from "@/components/TruncateTooltip.vue";
import { getEvidenceChartOptions } from "@/utils/evidenceChartUtils";
import GraphModal from './GraphModal.vue';
import MiniButton from './Buttons/MiniButton.vue';
import html2canvas from 'html2canvas';
import { copyImageToClipboard } from '@/utils/clipboardUtils';

export default {
  name: "LikelyRootCauses",
  components: {
    TruncateTooltip,
    HighchartsChart: Chart,
    GraphModal,
    MiniButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    metricGraphs: {
      type: Array,
      required: true,
    },
    logGraphs: {
      type: Array,
      required: true,
    },
    timeOfInterest: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    candidateIssues() {
      return this.data;
    },
  },
  data() {
    return {
      showModal: false,
      selectedGraph: null,
      copyLoading: null,
      copySuccess: null,
    };
  },
  methods: {
    getEvidenceGraph(id) {
      // First check metric graphs
      const metricGraph = this.findGraphById(id, this.metricGraphs);
      if (metricGraph) return metricGraph;

      // Then check log graphs
      const logGraph = this.findGraphById(id, this.logGraphs);
      if (logGraph) return logGraph;

      

      return null;
    },

    findGraphById(id, graphs) {
      // First try to find a direct match at the metric level
      const fullGraph = graphs.find(graph => graph.short_uuid === id);
      if (fullGraph) return fullGraph;

      // If no direct match, look for the ID in individual series
      for (const graph of graphs) {
        if (graph.series_data) {
          const matchingSeries = graph.series_data.find(series => series.short_uuid === id);
          if (matchingSeries) {
            return {
              ...graph,
              metrics: [matchingSeries],
              short_uuid: matchingSeries.short_uuid,
              query: matchingSeries.parent_query,
              platform_link: matchingSeries.platform_link,
              time_of_interest: matchingSeries.time_of_interest,
              logs_summary_stats: matchingSeries.logs_summary_stats
            };
          }
        }
      }
      return null;
    },
    getMetricChartOptions(graph) {
      return getEvidenceChartOptions(graph, {
        noTitle: true,
        timeOfInterest: graph.time_of_interest,
        hasLogs: graph.sample_logs?.logs.length > 0
      });
    },
    openModal(graph) {
      if (!graph) {
        console.error("Graph not found");
        return;
      }
      this.selectedGraph = graph;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedGraph = null;
    },
    openPlatformLink(url) {
      window.open(url, '_blank');
    },
    highlightText(text, highlights) {
      if (!highlights || !text) return text;
      
      let result = text;
      highlights.forEach(highlight => {
        const regex = new RegExp(this.escapeRegExp(highlight), 'gi');
        result = result.replace(regex, match => `<code class="highlight">${match}</code>`);
      });
      return result;
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
    formatLogFields(log) {
      if (!log.top_fields || !log.data) return '';
      return log.top_fields
        .map(field => `${field}: ${log.data[field]}`)
        .join(' | ');
    },
    async copyAsPNG(index) {
      try {
        this.copyLoading = index;
        const candidateElement = this.$refs[`candidate${index}`][0];
        if (!candidateElement) return;

        // Create clone
        const clone = candidateElement.cloneNode(true);
        document.body.appendChild(clone);
        clone.style.position = 'absolute';
        clone.style.left = '-9999px';
        clone.style.top = '-9999px';

        // Wait for clone to be fully rendered
        await new Promise(resolve => setTimeout(resolve, 100));

        // Apply text colors and styles to clone
        const styles = `
          .segment-header h2 {
            color: #FFFFFF !important;
            font-weight: 600 !important;
          }
          .segment-header p {
            color: #E7E8E7 !important;
          }
          .event-summary {
            color: #E7E8E7 !important;
          }
          .graph-title {
            color: #FFFFFF !important;
          }
          .log-timestamp {
            color: #E7E8E7 !important;
          }
          .log-message {
            color: #E7E8E7 !important;
          }
          .stat-field-name {
            color: #C2C5C2 !important;
          }
          .graph-tag {
            color: #E7E8E7 !important;
          }
        `;

        const styleSheet = document.createElement('style');
        styleSheet.textContent = styles;
        clone.appendChild(styleSheet);

        const canvas = await html2canvas(clone, {
          backgroundColor: '#0C160B',
          scale: 2,
          logging: false,
          useCORS: true,
          allowTaint: true,
          onclone: (clonedDoc, element) => {
            element.style.height = 'auto';
            element.style.overflow = 'visible';
            
            const scrollContainers = element.querySelectorAll('.segment-content');
            scrollContainers.forEach(container => {
              container.style.height = 'auto';
              container.style.overflow = 'visible';
            });

            // Ensure all text elements have proper color
            element.querySelectorAll('h2').forEach(h2 => {
              h2.style.color = '#FFFFFF';
              h2.style.fontWeight = '600';
            });

            element.querySelectorAll('.segment-header p').forEach(p => {
              p.style.color = '#E7E8E7';
            });

            element.querySelectorAll('.event-summary').forEach(summary => {
              summary.style.color = '#E7E8E7';
            });

            element.querySelectorAll('.graph-title').forEach(title => {
              title.style.color = '#FFFFFF';
            });

            element.querySelectorAll('.log-timestamp, .log-message').forEach(log => {
              log.style.color = '#E7E8E7';
            });

            element.querySelectorAll('.stat-field-name').forEach(field => {
              field.style.color = '#C2C5C2';
            });

            element.querySelectorAll('.graph-tag').forEach(tag => {
              tag.style.color = '#E7E8E7';
            });
          }
        });

        await copyImageToClipboard(canvas);
        
        this.copyLoading = null;
        this.copySuccess = index;
        setTimeout(() => {
          this.copySuccess = null;
        }, 3000);

      } catch (err) {
        console.error('Failed to copy:', err);
        this.copyLoading = null;
      } finally {
        const clone = document.querySelector('div[style*="-9999px"]');
        if (clone) document.body.removeChild(clone);
      }
    },
  },
};
</script>

<style scoped>

.timeline {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.timeline-segment {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.segment-content {
  display: flex;
  flex-direction: row; /* Change to row to place events horizontally */
  gap: 16px;
  padding: 0 16px 16px 16px;
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.event-section {
  display: flex;
  flex-direction: column;
  width: 550px; /* Base width for single graph */
  min-width: fit-content; /* Allow expansion for multiple graphs */
  flex: 0 0 auto;
}

.chart {
  display: flex;
  gap: 16px;
  width: fit-content; /* Change from 100% to fit-content */
  min-width: 550px; /* Ensure minimum width matches single graph */
}

.graph-container {
  width: 550px;
  min-width: 550px;
  max-width: 550px;
  height: 400px; /* Set fixed height */
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  transition: border-color 0.2s ease, transform 0.2s ease;
  position: relative;
}

.graph-container:hover {
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
}

/* Make sure the highcharts container takes full width */
.graph-container :deep(.highcharts-container) {
  width: 100% !important;
}

.graph-container :deep(.highcharts-root) {
  width: 100% !important;
}

.graph-description {
  color: var(--colors-white, #fff);
  font-size: 18px;
  line-height: 1.4;
  font-weight: 700;
}
.event-index-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.event-index-divider {
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 16px 0;
}

.event-index {
  font-size: 1rem;
  font-weight: 600;
  color: #dbdcda;
  background-color: #2a2f28;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.segment-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.segment-header {
  padding: 8px 16px 16px 16px;
  margin-bottom: 15px;
  background-color: #161F15;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.segment-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
}

.segment-header p {
  line-height: 1.5;
  display: inline;
}

.segment-header p :deep(code) {
  display: inline-block;
  margin: 0 2px;
}

.high-confidence {
  display: inline-block;
  font-weight: 500;
  color: #1b4d36;
  background-color: #00d478;
  padding: 4px 12px;
  border-radius: 16px;
  margin: 10px 0;
  font-size: 0.875rem;
}

.medium-confidence {
  display: inline-block;
  font-weight: 500;
  color: #664C00;
  background-color: #d49d00;
  padding: 4px 12px;
  border-radius: 16px;
  margin: 10px 0;
  font-size: 0.875rem;
}

.segment-content::-webkit-scrollbar {
  height: 12px;  /* Doubled from 6px */
  cursor: pointer;
}

.segment-content::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 12px;  /* Increased to match new height */
  background-clip: padding-box;
  border: 1px solid transparent;
  min-height: 12px;  /* Increased to match new height */
}

.segment-content::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 6px;
  /* Add margin to ensure track is clickable */
  margin: 0 2px;
}

.graph-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 196px;
  color: var(--colors-neutral-gray-600, #dbdcda);
  font-size: 14px;
}

.graph-tags {
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow-x: auto;
  background: #161F15;
  margin: 0 -12px -12px -12px;
  padding: 12px 20px;
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid var(--colors-base-neutral-gray-100, #353E34);
  height: 64px;
  min-height: 64px;
  max-height: 64px;
}

.stat-row {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: fit-content;
}

.stat-field-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--colors-base-neutral-gray-500, #C2C5C2);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.stat-tags {
  display: flex;
  gap: 4px;
}

.graph-tag {
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--colors-base-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08));
  white-space: nowrap;
  color: var(--colors-base-neutral-gray-700, #E7E8E7);

  /* text-xxs/semibold */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 120% */  
}

@media (max-width: 700px) {
  .segment-content {
    flex-direction: column; /* Stack vertically on mobile */
  }
  
  .event-section {
    width: 100%;
  }
  
  .graph-container {
    min-width: 382px;
  }
}

.event-summary {
  padding: 8px;
  font-size: 0.9375rem;
  line-height: 1.5;
  border-radius: 0 4px 4px 0;
  width: 100%;
  min-width: 550px; /* Match minimum width of chart */
}

.event-summary p {
  margin: 0;
  width: 100%;
}

.inline-exit {
  position: absolute;
  top: 8px;
  right: 8px;
  margin: 0;
  z-index: 1;
}

.exit-button {
  margin-top: 0px;
  margin-bottom: 0px;
}

:deep(.highlight) {
  background-color: #1f291f;
  color: #40853e;
  padding: 2px 6px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.9em;
  white-space: nowrap;
  font-weight: 600;
  border: 1px solid #2a3b29;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.segment-header h2 :deep(.highlight) {
  font-size: 1.1em;
  font-weight: inherit;
}

.graph-title {
  padding-right: 34px; /* Leave space for the button */
  width: 100%;
  font-size: 0.875rem;
}

.graph-title :deep(.truncate-text) {
  color: var(--colors-base-foreground, #FFF);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.compact-chart {
  height: 160px;
}

.compact-chart :deep(.highcharts-container) {
  height: 160px !important;
}

.compact-tags {
  height: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}

.logs-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 12px;
  height: 160px;
}

.logs-title {
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.logs-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 4px;
  overflow: hidden;
}

.log-timestamp {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--colors-base-neutral-gray-50-alpha, rgba(255, 255, 255, 0.08));
}

.log-message {
  display: flex;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  background: var(--colors-base-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  line-height: 1.3;
}

.confidence-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.event-summary :deep(.truncate-text) {
  color: var(--colors-base-neutral-gray-700, #E7E8E7);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

</style>
