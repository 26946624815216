<template>
  <div id="app">
    <LeftNavBar
      class="left-nav"
      :sessions="sessions"
      :currentSession="currentSession"
      :username="userId"
      :auth0="auth0"
      :email="userEmail"
      :selectedConfig="selectedConfig"
      :configs="configs"
      @update:currentSession="setCurrentSession"
      @update:selectedConfig="setSelectedConfig"
      @session-deleted="handleSessionDeleted"
      @session-renamed="handleSessionRenamed"
    />
    <div class="main-container">
      <div v-if="currentSession" class="active-investigation">
        <!-- Active Investigation Content -->
        <div class="investigation-content">
          <div class="chat-container">
            <div class="chat-messages">
              <ChatMessage
                v-for="message in chatMessages"
                :key="message.id"
                :type="message.type"
                :message="message.text"
                :parsedMessage="message.parsedMessage"
                class="message-component"
                :messageId="message.id"
                :avatarSrc="
                  message.type === 'system'
                    ? require('@/assets/avatar_logo.svg')
                    : require('@/assets/default_profile.svg')
                "
                :artifacts="message.artifacts"
                :agentLogs="message.agentLogs"
                :isStreaming="message.isStreaming"
                @submit-message-feedback="submitMessageFeedback"
                :username="userId"
                :auth0="auth0"
                :email="userEmail"
                :sessionId="currentSession"
              />
              <LoadingSpinner
                :isLoading="isStreamingChat && isSetSession"
                class="chat-loading-spinner"
              />
            </div>
          </div>
        </div>
        <!-- Floating Input Field and Controls -->
        <div
          v-if="false"
          class="floating-input-container"
        >
          <div class="landing-toggle-submit-recommendation">
            <div class="toggle-container">
              <!-- Toggle switch comment -->
            </div>
            <div class="manual-question-inputs">
              <InputField
                v-if="toggleValue == false"
                class="question-input"
                label="ManualQuestion"
                placeholder="Continue your investigation with a follow-up question"
                :value="inputValue"
                @inputField="updateInputValue"
                @enter="onEnterKey"
                :questions="questions"
                :agentTypeValue="inputAgentType"
                @update:agentTypeValue="updateAgentType"
                :agentTypeOptions="agentTypeOptions"
                :submitDisabled="isSubmitButtonDisabled"
                @submit="startInvestigation"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="landing-page">
        <img
          src="@/assets/full_logo.svg"
          alt="Full Logo"
          class="landing-logo"
        />
        <p class="landing-text">Untangle system failures the easy way.</p>
        <!-- <p class="landing-text-2">Prototype Warning: Limited tools (Datadog logs); errors are expected.</p> -->
        <InputForm
          label="Investigation"
          :value="inputValue"
          @inputField="updateInputValue"
          @enter="onEnterKeyLanding"
          :questions="questions"
          :agentTypeValue="inputAgentType"
          @update:agentTypeValue="updateAgentType"
          :agentTypeOptions="agentTypeOptions"
          :selectedConfig="selectedConfig"
          :submitDisabled="isLandingPageSubmitDisabled"
          @submit="createSession"
        />
        <!-- <AlertTable 
          :alerts="alerts" 
          @alert-row-clicked="sendAlertToForm"
        /> -->
        <div class="landing-toggle-submit">
          <div class="toggle-container">
            <!-- 
            Uncomment to deploy beyond Dripshop
            <ToggleSwitch :value="toggleValue" @input="toggleValue = $event" />
            <span>Auto-investigate</span>
            -->
          </div>
          <!-- <PrimaryButton type="primary" icon="arrow" @click="createSession" :disabled="isLandingPageSubmitDisabled">Submit</PrimaryButton> -->
        </div>
        <!-- <LoadingSpinner :isLoading="isCreatingSession" /> -->
        <div class="loading-spinner-container">
          <LoadingSpinner :isLoading="isCreatingSession" />
        </div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNavBar from "./components/LeftNavBar.vue";
import InputField from "./components/InputField.vue";
import InputForm from "./components/InputForm.vue";
// import AlertTable from './components/AlertTable.vue';

import ChatMessage from "./components/ChatMessage.vue";
import LoadingSpinner from "./components/LoadSpinner.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { reactive } from "vue";

export default {
  name: "App",
  components: {
    LeftNavBar,
    InputField,
    InputForm,
    ChatMessage,
    LoadingSpinner,
    // AlertTable
  },
  data() {
    return {
      // existing data properties
      currentSession: "",
      inputValue: "Start Time:\n\nSummary:\n",
      inputAgentType: "log_agent", // Default agent type
      toggleValue: false,
      chatMessages: [],
      agentLogs: [],
      questions: [],
      sessions: [],
      isCreatingSession: false,
      isLoadingQuestions: false,
      isStreamingChat: false,
      isSetSession: false,
      waitingOnAuth: false,
      userId: "",
      userEmail: "",
      url: process.env.VUE_APP_API_URL || "https://devapi.traversal.com", //'https://traversal-agent.traversal.svc.meow.nyc2.internal.digitalocean.com', // 'https://api.traversal.com',
      isCollapsed: true, // Added isCollapsed property
      selectedConfig: "", // Add this line
      agentTypeOptions: [{ value: "log_agent", label: "Search Logs" }],
      alerts: [],
      errorMessage: "", // Add this line
    };
  },
  mounted() {
    // existing code
    const auth0Context = useAuth0();
    this.auth0 = auth0Context;
    const { isAuthenticated, loginWithRedirect, user, isLoading } =
      auth0Context;

    const urlParams = new URLSearchParams(window.location.search);
    const sharedSessionId = urlParams.get("shared_session_id");
    if (sharedSessionId) {
      // Show loading
      this.isCreatingSession = true;
    }

    // Function to check the authentication status after isLoading completes
    const checkAuth = () => {
      if (!isLoading.value) {
        if (!isAuthenticated.value) {
          console.log("User is not authenticated, sending to Auth0 to login");
          this.waitingOnAuth = true;
          loginWithRedirect();
        } else if (isAuthenticated.value) {
          console.log(
            "User is authenticated the user id sub is :",
            user.value.sub
          );
          this.userId = user.value.sub;
          this.userEmail = user.value.email;
          this.waitingOnAuth = false;

          this.fetchConfigs();
          this.fetchSessions();
          //TODO populate default agent type

          // Check if there is a shared session id in the URL
          const urlParams = new URLSearchParams(window.location.search);
          const sharedSessionId = urlParams.get("shared_session_id");
          if (sharedSessionId) {
            this.loadSharedSession(sharedSessionId);
            this.isCreatingSession = false;
          }
        }
      }
    };

    // Watch for changes to isLoading and check auth status once it's done loading
    let interval = setInterval(() => {
      if (!isLoading.value) {
        checkAuth();
        clearInterval(interval); // Clear the interval once the check is done
      }
    }, 100);

    // Stop checking after 15 seconds to avoid infinite loop
    setTimeout(() => clearInterval(interval), 15000);
  },
  computed: {
    isSubmitButtonDisabled() {
      return (
        (!this.inputValue &&
          !this.questions.some((question) => question.selected) &&
          !this.toggleValue) ||
        this.isStreamingChat
      );
    },
    isLandingPageSubmitDisabled() {
      return !this.inputValue || this.isCreatingSession;
    },
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    updateAgentType(value) {
      this.inputAgentType = value;
    },
    setSelectedConfig(config) {
      this.selectedConfig = config;
      this.fetchAgentTypes();
    },
    updateInputValue(event) {
      if (typeof event === "string") {
        this.inputValue = event;
      } else if (event && event.target) {
        this.inputValue = event.target.value;
      } else {
        console.warn("updateInputValue: unexpected event", event);
      }
    },
    onEnterKey() {
      if (!this.isSubmitButtonDisabled) {
        this.startInvestigation();
      }
    },
    onEnterKeyLanding() {
      if (!this.isLandingPageSubmitDisabled) {
        this.createSession();
      }
    },
    updateQuestions(updatedQuestions) {
      this.questions = updatedQuestions;
    },
    handleSessionDeleted(sessionId) {
      if (this.currentSession === sessionId) {
        this.resetInvestigation();
      }
      this.fetchSessions();
    },
    handleSessionRenamed() {
      this.fetchSessions();
    },
    async loadSharedSession(sharedSessionId) {
      const { getAccessTokenSilently } = this.auth0;
      const token = await getAccessTokenSilently();
      try {
        const response = await fetch(
          `${this.url}/get_shared_session?shared_session_id=${sharedSessionId}&user_email=${this.userEmail}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          console.error("Failed to load shared session:", response.statusText);
          // Add check for doapp in URL
          const errorMessage = window.location.href.includes('doapp') 
            ? "Unable to connect. Please check if you have access to this app and are connected to the VPN"
            : "404 Error: Invalid share link";
          this.errorMessage = errorMessage;
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );
          return;
        }
        const data = await response.json();
        if (data.session_id) {
          this.setCurrentSession(data.session_id);
        } else {
          console.error("Failed to load shared session");
          // Add check for doapp in URL
          const errorMessage = window.location.href.includes('doapp')
            ? "Unable to connect. Please check if you have access to this app and are connected to the VPN"
            : "404 Error: Invalid share link";
          this.errorMessage = errorMessage;
        }
      } catch (error) {
        console.error("Error loading shared session:", error);
        // Add check for doapp in URL
        const errorMessage = window.location.href.includes('doapp')
          ? "Unable to connect. Please check if you have access to this app and are connected to the VPN"
          : "404 Error: Invalid share link";
        this.errorMessage = errorMessage;
      }
      // reset the URL
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    async submitMessageFeedback(chatId, feedbackMessage) {
      try {
        const { getAccessTokenSilently } = this.auth0; // Now we use the stored context
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/save_message_feedback`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            session_id: this.currentSession,
            chat_id: chatId,
            feedback_message: feedbackMessage,
          }),
        });
        const data = await response.json();
        if (data.status === "success") {
          console.log("Message feedback saved successfully.");
        } else {
          console.error("Failed to save message feedback:", data.message);
        }
      } catch (error) {
        console.error("Error in submitMessageFeedback:", error);
      }
    },
    async submitQuestionFeedbackFull(chatId, feedbackMessage, content) {
      try {
        const { getAccessTokenSilently } = this.auth0; // Now we use the stored context
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/save_question_feedback`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            session_id: this.currentSession,
            chat_id: chatId,
            feedback_message: feedbackMessage,
            content: content,
          }),
        });
        const data = await response.json();
        if (data.status === "success") {
          console.log("Question feedback saved successfully.");
        } else {
          console.error("Failed to save question feedback:", data.message);
        }
      } catch (error) {
        console.error("Error in submitQuestionFeedback:", error);
      }
    },
    submitQuestionFeedback(feedbackMessage, content) {
      const lastChatMessage = this.chatMessages[this.chatMessages.length - 1];
      if (lastChatMessage) {
        this.submitQuestionFeedbackFull(
          lastChatMessage.id,
          feedbackMessage,
          content
        );
      }
    },
    // end of feedback functions
    async fetchSessions() {
      console.log("Fetching sessions for user id:", this.userId);

      const { getAccessTokenSilently, loginWithRedirect } = this.auth0; // Now we use the stored context
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (error) {
        console.error("Error getting access token:", error);
        // Redirect to login if there's an error getting the token
        await loginWithRedirect();
        return;
      }

      console.log("Fetching sessions with token:", token);

      try {
        const response = await fetch(
          `${this.url}/get_all_sessions?user_id=${this.userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // If the response status is 403, force a re-login
        if (response.status === 403) {
          console.warn("Received stale user id. Redirecting to login.");
          await loginWithRedirect();
          return;
        }

        const data = await response.json();
        this.sessions = data.all_sessions;
      } catch (error) {
        console.error("Error fetching sessions:", error);
      }
    },
    async fetchAgentTypes() {
      try {
        const { getAccessTokenSilently } = this.auth0; // Now we use the stored context
        const token = await getAccessTokenSilently();

        //Send userId, user email, and selected config to the backend
        const response = await fetch(
          `${this.url}/get_agent_types?user_id=${this.userId}&config_name=${this.selectedConfig}&user_email=${this.userEmail}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        this.agentTypeOptions = data.agent_types;
        // this.agentTypeOptions = [{ value: 'metric_agent', label: 'Search Metrics' }, { value: 'log_agent', label: 'Search Logs' }]; //TODO UNCOMMENT FOR TESTING
        this.inputAgentType = this.agentTypeOptions[0].value;
      } catch (error) {
        console.error("Error fetching agent types:", error);
      }
    },
    fetchConfigs() {
      const { getAccessTokenSilently } = this.auth0;

      getAccessTokenSilently().then((token) => {
        fetch(
          `${this.url}/get_available_configs?user_id=${this.userId}&user_email=${this.userEmail}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            this.configs = data.configs;
            if (this.configs == null) {
              this.configs = [];
            }
            if (this.configs.length > 0) {
              this.selectedConfig = this.configs[0];
            }
            // Emit event to parent
            this.fetchAgentTypes();
            this.fetchAlerts();
          })
          .catch((error) => {
            console.error("Error fetching configs:", error);
            // Add error message with doapp check
            const errorMessage = window.location.href.includes('doapp')
              ? "Unable to connect. Please check if you have access to this app and are connected to the VPN"
              : "Unable to connect. Please confirm if you have access with your organization admin or Traversal representative";
            this.errorMessage = errorMessage;
          });
      });
    },
    async fetchAlerts() {
      try {
        const { getAccessTokenSilently } = this.auth0;
        const token = await getAccessTokenSilently();

        const response = await fetch(
          `${this.url}/get_enriched_alerts?user_id=${this.userId}&config_name=${this.selectedConfig}&user_email=${this.userEmail}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        console.log("Received alerts:", data);

        if (data.enriched_alerts) {
          this.alerts = data.enriched_alerts;
        } else {
          console.log("No alerts found");
        }
      } catch (error) {
        console.error("Error fetching alerts:", error);
      }
    },
    async setCurrentSession(session) {
      this.isStreamingChat = true;
      this.isSetSession = true;
      this.isLoadingQuestions = true;

      if (this.$refs.investigationSummary) {
        this.$refs.investigationSummary.resetSummary();
      }

      if (session) {
        this.fetchSessions();
        this.resetInvestigation();
        this.currentSession = session;
        let loadedMessages = [];

        // pull and render chat messages from /get_session which takes session_id and returns chat_id, content, and type for messages in order as a json of list of dictionaries
        try {
          const { getAccessTokenSilently } = this.auth0; // Now we use the stored context
          const token = await getAccessTokenSilently();
          const response = await fetch(
            `${this.url}/get_session?session_id=${session}&user_id=${this.userId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          let last_question = "";
          this.chatMessages = [];

          for (let message of data) {
            if (message.message_type === "user") {
              last_question = message.content;
              loadedMessages.push({
                id: message.chat_id,
                type: message.message_type,
                text: message.content,
              });
            } else {
              this.agentLogs.push({
                id: message.chat_id,
                text: "## " + last_question + " | Tasks\n" + message.content,
              });

              // Process artifacts using the new method
              const artifacts = await this.processArtifacts(message.artifacts);

              let parsedMessage = null;
              try {
                parsedMessage = JSON.parse(message.parsed_output);
                try {
                  parsedMessage.next_steps = parsedMessage.next_steps.map(
                    (next_step) => {
                      return { text: next_step };
                    }
                  );
                } catch (error) {
                  console.error("Error parsing next steps:", error);
                }
              } catch (error) {
                console.error("Error parsing message:", error);
              }

              console.log("The parsed message is: ", parsedMessage);
              console.log("The artifacts are: ", artifacts);

              loadedMessages.push({
                id: message.chat_id,
                type: message.message_type,
                text: message.parsed_output,
                parsedMessage: parsedMessage,
                artifacts: artifacts,
                agentLogs: message.content,
                isStreaming: false,
              });
            }
          }
        } catch (error) {
          console.error("Error fetching chat messages:", error);
        }
        this.chatMessages = loadedMessages;
        this.isStreamingChat = false;
        this.isSetSession = false;
        // this.fetchRecommendedQuestions();

        if (this.$refs.investigationSummary) {
          this.$refs.investigationSummary.getSummary(this.currentSession);
        }
      } else {
        this.fetchSessions();
        this.resetInvestigation();
      }
    },
    async resetInvestigation() {
      this.inputValue = "";
      this.chatMessages = [];
      this.agentLogs = [];
      this.questions = [];
      this.currentSession = "";
    },
    // Create new session from landing page
    async createSession() {
      this.isCreatingSession = true;
      this.isLoadingQuestions = true;
      this.isStreamingChat = true;
      if (this.$refs.investigationSummary) {
        this.$refs.investigationSummary.resetSummary();
      }

      this.chatMessages = [];
      this.agentLogs = [];
      this.questions = [];
      this.currentSession = "";

      console.log(
        "Creating session from landing page with input:",
        this.inputValue
      );

      try {
        const { getAccessTokenSilently } = this.auth0;
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/create_session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_id: this.userId,
            user_message: this.inputValue,
            user_email: this.userEmail,
            config_name: this.selectedConfig,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          this.currentSession = data.session_id;
          await this.fetchSessions();
          console.log("Finished creating session");
        } else {
          console.error("Failed to start the session:", response.statusText);
        }
      } catch (error) {
        console.error("Error in startSession:", error);
      }
      this.isCreatingSession = false;
      this.isStreamingChat = false;
      this.startInvestigation();
    },
    // Artifact
    async fetchArtifact(artifactData) {
      try {
        const { getAccessTokenSilently } = this.auth0;
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${this.url}/get_artifact?session_id=${artifactData.session_id}&chat_id=${artifactData.chat_id}&tool_call_id=${artifactData.tool_call_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const artifact = await response.json();
          // Process the artifact
          return artifact;
        } else {
          console.error("Failed to fetch artifact:", response.statusText);
          return null;
        }
      } catch (error) {
        console.error("Error fetching artifact:", error);
        return null;
      }
    },
    async processArtifacts(artifactsDataArray) {
      const processedArtifacts = [];

      for (let artifactData of artifactsDataArray) {
        let retrievedArtifact = await this.fetchArtifact(artifactData);

        if (retrievedArtifact) {
          processedArtifacts.push({
            images: retrievedArtifact.images || [],
            urls: retrievedArtifact.urls || [],
            texts: retrievedArtifact.texts || [],
            dataframes: retrievedArtifact.dataframes || [],
            jsons: retrievedArtifact.jsons || [],
            logs: retrievedArtifact.logs?.logs || [],
            metrics: retrievedArtifact.metrics?.metric_queries || [],
            error: retrievedArtifact.error || null,
            code: retrievedArtifact.code || null,
            metric_metadata: retrievedArtifact.metric_metadata || null,
            start_time: retrievedArtifact.start_time || null,
            end_time: retrievedArtifact.end_time || null,
            action_type: retrievedArtifact.action_type || null,
            terminal_output: retrievedArtifact.terminal_output || null,
            artifact_display: retrievedArtifact.artifact_display || [],
            tool_name: artifactData.tool_name,
            tool_args: artifactData.tool_args,
            tool_call_id: artifactData.tool_call_id,
          });
        }
      }

      console.log("Processed artifacts:", processedArtifacts);

      return processedArtifacts;
    },
    streamText(text, callback, delay = 5) {
      if (!text) {
        return;
      }
      let index = 0;
      function appendChar() {
        if (index < text.length) {
          callback(text[index]);
          index++;
          setTimeout(appendChar, delay);
        }
      }
      appendChar();
    },
    // Start investigation once you're in an active session
    async startInvestigation() {
      this.isStreamingChat = true;
      if (this.toggleValue) {
        this.inputValue = "";
      }

      let selectedQuestionsText = "";
      let selectedQuestionType = "";
      if (this.inputValue) {
        selectedQuestionsText = this.inputValue;
        selectedQuestionType = this.inputAgentType; // TODO REPLACE WITH ENDPOINT FOR INFERRING AGENT TYPE
      } else {
        if (this.toggleValue) {
          this.questions[0].selected = true;
        }

        const selectedQuestions = this.questions.filter(
          (question) => question.selected
        );
        selectedQuestionsText = selectedQuestions
          .map((question) => question.text)
          .join(" ");
        selectedQuestionType = selectedQuestions.map(
          (question) => question.agent_type
        )[0];
      }
      this.inputValue = "";

      if (selectedQuestionType === "FINAL") {
        console.log("Final question selected, ending investigation");
        this.isLoadingQuestions = false;
        this.isStreamingChat = false;
        // Eventually save this as a conclusion to our backend.
        return;
      }

      console.log(
        "Starting investigation with input:",
        selectedQuestionsText,
        "and agent type:",
        selectedQuestionType
      );
      console.log(
        "Input value and agent type are:",
        this.inputValue,
        this.inputAgentType
      );

      // send it to /setup_stream_chat
      let systemMessageId = "tmp";
      try {
        const { getAccessTokenSilently } = this.auth0; // Now we use the stored context
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/setup_stream_chat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_message: selectedQuestionsText,
            user_id: this.userId,
            session_id: this.currentSession,
            agent_type: selectedQuestionType,
            ranked_questions: this.questions,
            user_email: this.userEmail,
            config_name: this.selectedConfig,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          this.chatMessages.push({
            id: data.user_chat_id,
            type: "user",
            text: data.question,
          });
          systemMessageId = data.system_chat_id;
          this.chatMessages.push({
            id: systemMessageId,
            type: "system",
            text: "",
          });
        } else {
          if (response.status === 404) {
            const errorData = await response.json();
            if (errorData.detail === "User not found in configured users") {
              this.resetInvestigation();
              this.isCreatingSession = false;
              this.isStreamingChat = false;
              this.errorMessage = "Invalid User: Please message your organization administrator (or Traversal representative) to be granted access";
              return;
            }
          }
          console.error("Failed to start the session:", response.statusText);
        }
      } catch (error) {
        console.error("Error in startInvestigation:", error);
      }
      //TODO CHECK IF THIS BREAKS ANYTHING
      this.questions = [];

      // send it to /stream_chat
      try {
        const { getAccessTokenSilently } = this.auth0; // Now we use the stored context
        const token = await getAccessTokenSilently();

        const response = await fetch(`${this.url}/stream_chat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_message: selectedQuestionsText,
            user_id: this.userId,
            session_id: this.currentSession,
            agent_type: selectedQuestionType,
            chat_id: systemMessageId,
            user_email: this.userEmail,
            config_name: this.selectedConfig,
          }),
        });

        if (response.ok) {
          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");
          let isStream = false;
          let isConclusion = false;
          let isArtifact = false;
          let isEndOfChat = false;
          let tempConclusion = "";

          let systemMessage = this.chatMessages.find(
            (msg) => msg.id === systemMessageId
          );
          systemMessage.isStreaming = true;

          this.agentLogs.push({
            id: systemMessageId,
            text: "## " + selectedQuestionsText + " | Tasks\n",
          });
          systemMessage.agentLogs = "";

          // Main text stream processing loop
          while (!isEndOfChat) {
            const { value, done: doneReading } = await reader.read();
            isEndOfChat = doneReading;
            let curr_chunk = decoder.decode(value, { stream: !isEndOfChat });

            if (curr_chunk) {
              let chunk_sections = curr_chunk.split("[STREAM_SECTION]");
              for (let chunk of chunk_sections) {
                if (chunk.includes("[STREAM]")) {
                  isStream = !isStream;
                  chunk = chunk.replace("[STREAM]", "");
                }
                if (chunk.includes("[CONCLUSION]")) {
                  isConclusion = !isConclusion;
                  chunk = chunk.replace("[CONCLUSION]", "");
                }
                if (chunk.includes("[ARTIFACT]")) {
                  isArtifact = !isArtifact;
                  chunk = chunk.replace("[ARTIFACT]", "");
                }
                if (chunk.includes("[END_OF_CHAT]")) {
                  isEndOfChat = true;
                  chunk = chunk.replace("[END_OF_CHAT]", "");
                }

                if (isArtifact) {
                  let data = null;
                  try {
                    data = JSON.parse(chunk);
                  } catch (error) {
                    data = null;
                  }
                  if (data && data.tool_message_output_type === "artifact") {
                    console.log("Received artifact:", data);
                    if (!systemMessage.artifacts) {
                      systemMessage.artifacts = [];
                    }

                    let processedArtifacts = await this.processArtifacts([
                      data,
                    ]);
                    systemMessage.artifacts.push(processedArtifacts[0]);

                    //systemMessage.artifacts.push(data);
                    //this.fetchAndProcessArtifact(data, systemMessage);
                  }
                  continue;
                }
                if (isStream) {
                  // Stream to agentLogs
                  this.agentLogs[this.agentLogs.length - 1].text += chunk;
                  systemMessage.agentLogs += chunk;
                }
                if (isConclusion) {
                  // Append remaining content to system message
                  //systemMessage.text += chunk;
                  tempConclusion += chunk;
                }
              }
            }
          }

          // Chunk done streaming - parse systemMessage JSON and replcae with right field
          if (tempConclusion) {
            try {
              const systemMessageContent = JSON.parse(tempConclusion);
              const fullText = JSON.parse(systemMessageContent.result);

              systemMessage.text = "";

              systemMessage.parsedMessage = reactive({
                answer: "",
                evidence: [],
                next_steps: [],
              });

              this.streamText(fullText.answer, (char) => {
                systemMessage.parsedMessage.answer += char;
              });
              // Add each evidence in pieces to parsed message, adding .summary, then .details in pieces, then .tool_id

              // Example usage for evidence.summary:
              fullText.evidence.forEach((evidence) => {
                let evidenceObj = reactive({
                  summary: "",
                  details: "",
                  tool_id: evidence.tool_id,
                });

                this.streamText(evidence.summary, (char) => {
                  evidenceObj.summary += char;
                });

                this.streamText(evidence.details, (char) => {
                  evidenceObj.details += char;
                });

                systemMessage.parsedMessage.evidence.push(evidenceObj);
              });

              // systemMessage.parsedMessage.next_steps = fullText.next_steps

              fullText.next_steps.forEach((next_step) => {
                let nextStepObj = reactive({ text: "" });
                systemMessage.parsedMessage.next_steps.push(nextStepObj);
                this.streamText(next_step, (char) => {
                  nextStepObj.text += char;
                });
              });
            } catch (e) {
              // If parsing fails, keep the text as is
              console.warn(
                "Could not parse one of the message text as JSON:",
                e
              );
              try {
                const systemMessageContent = JSON.parse(tempConclusion);
                systemMessage.text = systemMessageContent.result;
              } catch (e) {
                console.warn(
                  "Could not parse either of the message text as JSON:",
                  e
                );
                systemMessage.text = tempConclusion;
              }
            }
          }
          systemMessage.isStreaming = false;
        } else {
          console.error("Failed to start the session:", response.statusText);
        }
      } catch (error) {
        console.error("Error in startInvestigation:", error);

        // Fail gracefully - if a systemMessage was added, give it 404 error text. Otherwise, add a new systemMessage with 404 error text.
        let systemMessage = this.chatMessages.find(
          (msg) => msg.id === systemMessageId
        );
        if (systemMessage) {
          systemMessage.text = "Error in starting investigation: " + error;
          systemMessage.isStreaming = false;
        } else {
          this.chatMessages.push({
            id: systemMessageId,
            type: "system",
            text: "Error: 404 Not Found",
          });
        }
      }
      this.isStreamingChat = false;
      this.isLoadingQuestions = true;
      // this.fetchRecommendedQuestions();

      if (this.$refs.investigationSummary) {
        console.log("Updating summary in App.vue");
        this.$refs.investigationSummary.updateSummary(this.currentSession);
      }
    },
    fetchRecommendedQuestions() {
      //get index of selected question
      let selectedQuestionIndex = this.questions.findIndex(
        (question) => question.selected
      );
      this.questions = [];
      this.isLoadingQuestions = true;
      // make get request to /get_questions, sending it session_id and it should return JSON with questions (as a list of strings) and agent_types (as a list of strings). Both should be saved, and given ids before displayed in questionlist
      this.fetchSessions();
      try {
        //get requestion with session_id and user_id
        const { getAccessTokenSilently } = this.auth0; // Now we use the stored context

        getAccessTokenSilently().then((token) => {
          fetch(
            `${this.url}/get_questions?session_id=${this.currentSession}&user_id=${this.userId}&user_email=${this.userEmail}&config_name=${this.selectedConfig}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((response) => response.json())
            .then((data) => {
              this.questions = data.questions
                .map((question, index) => {
                  let agentType = data.agent_types[index];
                  if (agentType.includes("FINISH")) {
                    return null;
                  }
                  return {
                    id: index,
                    text: question,
                    agent_type: agentType,
                    selected: selectedQuestionIndex === index,
                  };
                })
                .filter((question) => question !== null); // remove nulls from the list

              this.isLoadingQuestions = false;
              // If auto-investigate is on, select the first question and then start the investigation
              if (this.toggleValue) {
                this.questions[0].selected = true;
                // wait for the next tick to ensure the selected question is rendered
                this.$nextTick(() => {
                  // this.startInvestigation();
                  this.$nextTick(() => {
                    this.startInvestigation();
                  });
                });
                //this.startInvestigation();
              }
            })
            .catch((error) => {
              console.error("Error fetching recommended questions:", error);
            });
        });
      } catch (error) {
        console.error("Error fetching recommended questions:", error);
      }
    },
  },
};
</script>

<style scoped>
/* General Styles */
body,
footer,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

#app {
  font-family: Inter, Helvetica, Arial, sans-serif;
  color: #ffffff;
  background-color: #0c160b;
  display: flex;
  padding: 0px;
  margin: 0;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
}

.main-container {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.active-investigation {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.investigation-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /*padding-bottom: 60px;*/ /* uncomment this to see floating block at the bottom of the page */
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
}

/* Landing Page Styles */
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 20px 0;
  margin-top: 40px;
  height: auto;
  overflow-y: auto;
}

.loading-spinner-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message {
  color: #ff4d4f;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
}

.chat-loading-spinner {
  margin-top: 60px;
}

.landing-logo {
  display: flex;
  width: 320.101px;
  height: var(--spacing-1560-px, 60px);
  padding-right: 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.landing-toggle-submit {
  display: flex;
  width: 832px;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.landing-toggle-submit-recommendation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.manual-question-inputs {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the InputField horizontally */
  width: 100%;
  margin: 0px;
}

.landing-text {
  color: var(--colors-neutral-gray-600, #dbdcda);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
  margin-top: 8px;
  margin-bottom: 24px;
}

.landing-text-2 {
  color: var(--red, #880000);
  text-align: center;
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  font-style: normal;
  line-height: 100%; /* 24px */
  margin-top: 8px;
  margin-bottom: 24px;
}

.toggle-container span {
  font-size: 14px;
}

.top-nav {
  height: 48px;
}

.agent-logs-container {
  flex: 1;
  border-left: 2px solid #353e34;
  padding: 16px;
  width: 25%;
}

.recommended-questions-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 75%; /* Default height when expanded */
}

.recommended-questions-container.collapsed {
  height: 100px; /* Adjust as needed */
}

.border-click-area {
  height: 20px; /* Clickable area */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-click-area:hover .border-line {
  background-color: #5a6657; /* Slightly lighter color */
}

.border-line {
  width: 100%;
  height: 2px; /* Visible border */
  background-color: #353e34;
  position: relative;
}

.arrow-icon {
  position: absolute;
  top: -3px; /* Adjust as needed to center vertically */
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #ffffff;
  transition: transform 0.3s;
}

.arrow-icon.collapsed {
  transform: translateX(-50%) rotate(180deg);
}

.recommended-questions-container-contents {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
}

.recommendation-text {
  color: var(--colors-neutral-gray-800, #f3f3f3);
  font-size: 14px;
  margin-bottom: 16px;
}

.question-input {
  width: 75%;
  margin: 0px;
}

.floating-input-container {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0px;
  z-index: 9;
  margin: 0;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
}

.message-component {
  display: flex;
  width: 100%;
  min-width: 0;
  overflow: visible;
}

/* Add these new styles for the dropdown */
.agent-type-dropdown {
  background-color: var(--colors-neutral-gray-50, #1f291f);
  color: var(--colors-neutral-gray-600, #dbdcda);
  border: 2px solid var(--colors-neutral-gray-600, #dbdcda);
  border-radius: 8px;
  padding: 8px;
  margin-left: 4px;
  font-size: 14px;
  font-family: Inter, Helvetica, Arial, sans-serif;
  cursor: pointer;
  height: 45px;
}

.agent-type-dropdown:focus {
  outline: none;
  border-color: var(--colors-green-green-500, #40853e);
}

.landing-toggle-submit-recommendation {
  display: flex;
  align-items: center;
}

.submit-button {
  margin-right: 24px;
}

/*  Scrollbar Styles */

.chat-container::-webkit-scrollbar {
  width: 12px; /* Adjust the width to make it slimmer */
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* White scrollbar thumb */
  border-radius: 6px; /* Rounded corners */
}

.chat-container::-webkit-scrollbar-track {
  background-color: #0c160b; /* Background color of the scrollbar track */
  border-radius: 6px; /* Rounded corners */
}
</style>
