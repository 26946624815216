<template>
  <div class="truncate-container" :title="hoverEnabled ? text : ''" @mouseover="hoverEnabled = true" @mouseleave="hoverEnabled = false">
    <span class="truncate-text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "TruncateTooltip",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hoverEnabled: false,
    };
  },
};
</script>

<style scoped>
.truncate-container {
  position: relative;
  display: inline-block;
  max-width: 100%; /* Full width truncation */
}

.truncate-text {
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: top;
  color: var(--colors-white, #fff);
  font-size: 18px;
  line-height: 1.4;
  font-weight: 700;
}

.truncate-container:hover::after {
  content: attr(title);
  position: absolute;
  left: 0;
  top: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: normal;
  z-index: 1000;
  transform: translateY(5px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
</style>
